import React, { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import { motion, useAnimation } from 'framer-motion';

import { fadeIn, slideUp } from './indexVariants';
import * as welcomeStyles from './welcomepodcast.module.scss';


 const Project = () =>  {
  const [ref, isVisible] = useInView({ threshold: 0.6 });
  const controls = useAnimation();
// styles


  useEffect(() => {
    if(isVisible)
      controls.start('animate');
  }, [isVisible, controls])

  return (
  	<section ref={ref}  className={welcomeStyles.backgroundbio}>
      <section ref={ref}  className={welcomeStyles.effectcolor}>
   	  <section ref={ref}  className={welcomeStyles.container}>
      
	  
       <div className={welcomeStyles.content}>
       <div class="container-fluid"> 
         <div class="row">
           <div class="col-md-12 text-center">
              <p>This is page is under construction. We’ll be back soon.</p>
           </div>
         </div>
                    
        </div>

        </div>
   

      </section>
      </section>

      
	</section>
	 
  )
}

export default Project;
