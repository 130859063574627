// extracted by mini-css-extract-plugin
export var iframe = "welcomepodcast-module--iframe--3CuNt";
export var containeriframe = "welcomepodcast-module--containeriframe--35guD";
export var container = "welcomepodcast-module--container--2eYyS";
export var content = "welcomepodcast-module--content--12J9Q";
export var logoimage = "welcomepodcast-module--logoimage--221UB";
export var imagen = "welcomepodcast-module--imagen--27OD2";
export var navItem = "welcomepodcast-module--nav-item--1EVup";
export var centrada = "welcomepodcast-module--centrada--2p5UP";
export var backgroundbio = "welcomepodcast-module--backgroundbio--3hTPH";
export var effectcolor = "welcomepodcast-module--effectcolor--3ReGw";
export var phoneimage = "welcomepodcast-module--phoneimage--1J2f6";
export var widthfit = "welcomepodcast-module--widthfit--2kLty";
export var divflex = "welcomepodcast-module--divflex--3FuG1";