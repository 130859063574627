export const ImagesBackground= {   
  fondo1 : '/static/bio-c82694d99c244f08a7606a7021707b07.jpeg' , 
  fondo2 : '/static/alec_slide_1-16852c189afb6ed28b7127da7b78117d.jpg' 
} ;

export const fadeIn = {
  initial: { opacity: 0 },
  animate: i => ({ 
    opacity: 1,
    transition: {
      duration: 1,
      delay: i
    } 
  }),
}

export const wordMarkVariants = {
  initial: {
    y: 50,
    opacity: 0,
  },
  animate: {
    y:0,
    opacity: 1,
    transition: {
      delay: 1,
      duration: 1,
    }
  },
}

export const lineVariants = {
  initial: {
    width: 0
  },
  animate: {
    width: '50%',
    transition: {
      delay: 2,
      duration: 1,
    }
  },
}

export const ulVariants = {
  animate: {
    opacity: 1,
    transition: {
      staggerChildren: 0.2,
      delayChildren: 3,
    }
  },
}

export const navItemVariants = {
  initial: {
    y: -20,
    opacity: 0
  },
  animate: {
    y: 0,
    opacity: 1,
    transition:{
      duration: 0.6
    }
  },
}

export const slideUp = {
  initial: i => ({
    y: i,
  }),
  animate: {
    y: 0
  }
}

export const bgcolor = {
  bgcolor: "bg-blue"
}