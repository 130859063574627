import React from 'react';
import wordmark from '../../images/AlecBaldwinWordMark.png'
import * as welcomeStyles from '../../components/index/welcomebio.module.scss';


//const Navbar = ({ navVisible }) =>

class Menu extends React.Component{
	constructor(props) {
		super(props);
	}

	render(){
		return (
			
		// aca coloco mi menu de navegacion
		//<nav className="navbar navbar-expand-md navbar-dark fixed-top bg-blue">
		<nav className={this.props.bgcolor}>
			<a className="navbar-brand" href="#"></a>
			<img className={welcomeStyles.logoimage} src={wordmark} alt="Alec Baldwin"/>
			 <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
		     	<span className="navbar-toggler-icon"></span>
		    </button>
		    <div className="collapse navbar-collapse" id="navbarCollapse">
			    <ul className="navbar-nav mr-auto">
			        <li className="nav-item active">
			          	<a className="nav-link" href="/index.html">Home</a>
			        </li>
			        <li className="nav-item">
			          	<a className="nav-link" href="/bio">Bio</a>
			        </li>
			        <li className="nav-item">
			          	<a className="nav-link" href="/project">Current Project</a>
			        </li>
			       <li className="nav-item">
			          	<a className="nav-link" href="https://www.instagram.com/alecbaldwininsta/">Social Media</a>
			        </li>
					<li className="nav-item">
			          	<a className="nav-link" href="/podcast">Here's The Thing</a>
			        </li>
					<li className="nav-item centrada">
			          	<a className="nav-link" href="https://www.facebook.com/AlecBaldwinFoundation">hilaria & alec Baldwin foundation</a>
			        </li>
					<li className="nav-item">
			          	<a className="nav-link" href="/rocks">30 rock/snl</a>
			        </li>
					<li className="nav-item">
			          	<a className="nav-link" href="/advocacy">Advocacy</a>
			        </li>
					<li className="nav-item">
			          	<a className="nav-link" href="/gallery">Gallery</a>
			        </li>
			    </ul>
			   
		    </div>
 
		</nav>
		)
	}
}

export default Menu;