import React, { useState } from 'react';

import WelcomeProject from '../components/index/project';
import Menu from '../components/menu/Menu';

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/css/bootstrap.min.css'; 

const project = ()  => {

  return ( 
      <>  
		<Menu bgcolor='navbar navbar-expand-md navbar-dark fixed-top bg-black'/>
        <WelcomeProject></WelcomeProject>
      </>
  )
} 

export default project;